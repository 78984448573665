import React from "react";
import PG_CPV_SECTION_ONE_LINK_LIST from "./index.content";
import TrackedLink from "../../../Analytics/TrackedLink";
import "./index.scss";

const SectionOne = () => {
  return (
    <section className="PgCPV-SectionOne">
      <div className="PgCPV-SectionOne__container">
        <h1 className="PgCPV-SectionOne__title">
          如何在使用VPN 的情况下安装AMZ侦探 PRO 插件
        </h1>
        <p className="PgCPV-SectionOne-text">
          <b>1.</b>
          安装AMZ侦探工具是一个简单的过程。首先请您确认您的电脑已经安装了最新的谷歌Chrome或者微软Edge浏览器。如果您不运行Chrome或者Edge浏览器，PRO插件将无法被安装到您的电脑上。
        </p>
        <div className="PgCPV-SectionOne__grayLine" />
        <p className="PgCPV-SectionOne-text">
          <b>2.</b>
          由于谷歌在中国大陆受限，在中国大陆的用户想要使用AMZ中文工具，平时需要有科学上外网的工具。如果您已有科学上外网的工具，请把它打开并下载安装我们的插件。如果没有可以尝试使用下列推荐的科学上外网的工具。
        </p>
        <ul className="PgCPV-SectionOne-list">
          {PG_CPV_SECTION_ONE_LINK_LIST.map(({ path, action }) => (
            <li key={action}>
              <div className="PgCPV-SectionOne-list__marker" />
              <TrackedLink
                category="CNProVpnLanding"
                action={action}
                target
                path={path}
              >
                {path}
              </TrackedLink>
            </li>
          ))}
        </ul>
        <div className="PgCPV-SectionOne__grayLine" />
        <p className="PgCPV-SectionOne-text PgCPV-SectionOne-text__lastText">
          <b>3.</b>
          完成科学上外网的工具下载安装并确认您已经成功地科学上外网后，可使用谷歌浏览器点击下载我们的插件。完成安装后即可使用。
        </p>
        <TrackedLink
          category="CNProVpnLanding"
          action="clickToDownload"
          class="PgCPV-SectionOne__downloadButton"
          path={`${process.env.SCOUT_EXT_PRO}?с=china`}
          showMobileProPopup
        >
          点击下载
        </TrackedLink>
      </div>
      <div className="PgCPV-SectionOne__yellowLine" />
    </section>
  );
};

export default SectionOne;
